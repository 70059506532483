.banner {
  position: relative;
  @media (max-width: 767px) {
    height: 100vh;
  }
  video {
    width: 100%;
    height: auto;
    display: block;
    @media (max-width: 767px) {
      height: 100vh;
      object-fit: none;
    }
  }
  &_data {
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    text-align: center;
    background: transparent;
    padding: 10px;
    border-radius: 8px;
    width: 100%;
  }
  h1 {
    font-size: var(--heading80);
    font-weight: 500;
    text-align: center;
    // text-transform: capitalize;
    line-height: 86px;
    padding-bottom: 30px;
    @media (max-width: 1366px) {
      line-height: 60px;
    }
    @media (max-width: 575px) {
      max-width: 500px;
      width: 100%;
      margin: auto;
    }
  }
  p {
    color: var(--white);
    font-weight: 400;
    text-align: center;
    padding-bottom: 60px;
    font-size: var(--headingTwo);
    @media (max-width:575px) {
      max-width: 295px;
      width: 100%;
      margin:auto;
      
    }
  }
}
