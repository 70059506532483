p {
  color: var(--commonPtext);
  line-height: 25px;
  font-size: var(--largeParaGraph16);
  font-weight: 400;
}

.aboutSec.container.reverse {
  flex-direction: row-reverse !important;
  margin-bottom: 199px;

  @media (max-width: 1259px) {
    flex-direction: column !important;
    gap: 0px;
    margin-bottom: 100px;
  }
}

.ant-drawer-content.headerItem_Main__rightSec {
  background-color: var(--bodybg);

  .ant-drawer-header{
    width: 100%;
  }
  .ant-drawer-body {
    padding-top: 0;
    
  }
  .navButtons{
    align-items: flex-start;
  }

  .ant-drawer-close {
    svg {
      color: var(--white);
    }
  }

  .ant-drawer-body {
    flex-direction: column;
    width: 100%;

    a {
      display: block;
      margin: 0;
      padding: 10px;
      border-radius: 10px;
      margin-bottom: 2px;

      &:hover {
       background-color: var(--dropborder);
        color: var(--black);
      }
    }
  }
}
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

