.roadmap {
  padding-top: 100px;

  @media (max-width: 991px) {
    padding-top: 30px;
  }
  h2 {
    text-align: center;
    background: var(--headinggradient);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: var(--heading60);
    line-height: 60px;
    max-width: 616px;
    font-weight: 600;
    margin: auto;
    @media (max-width: 991px) {
      text-align: left;
      margin: unset;
    }
  }
  .secureText {
    text-align: center;
    padding-top: 20px;
    padding-bottom: 100px;
    max-width: 634px;
    margin: auto;
    color: var(--textd1d1d1);
    @media (max-width: 1366px) {
      padding-bottom: 30px;
    }
    @media (max-width: 991px) {
      text-align: left;
      margin: unset;
      padding-bottom: 15px;
      padding-top: 0;
    }
  }
  &_inner {
    position: relative;
    @media (max-width: 991px) {
      padding-bottom: 40px;
    }
    video {
      width: 100%;
      height: auto;
      display: block;
   
      @media (max-width: 991px) {
        display: none;
      }
    }

    &_data {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: white;
      text-align: center;
      background: transparent;
      padding: 10px;
      border-radius: 8px;
      width: 100%;
      @media (max-width: 1366px) {
        // padding-top: 40px;
      }
      @media (max-width: 991px) {
        position: static;
        transform: unset;
        padding: 0;
      }
      span {
        font-size: var(--heading50);
        font-weight: 700;
        color: var(--white);
        opacity: 0.1;
        line-height: 50px;
        @media (max-width: 767px) {
          font-size: 20px;
          line-height: 26px;
        }
      }
      h4 {
        padding-bottom: 12px;
        font-size: var(--headingTwo);
        font-weight: 600;
      }
      p {
        font-weight: 300;
        font-size: var(--mediumParaGraph);
        color: var(--white);
        max-width: 386px;
        width: 100%;
        margin: auto;
      }
      .roadmapTriskel {
        display: flex;
        justify-content: space-between;
        @media (max-width: 991px) {
          flex-direction: column;
          gap: 15px;
        }
        .triskelWallet {
          display: flex;
          flex-direction: column;
          @media (max-width: 991px) {
            gap: 15px;
          }
          &_inner {
            display: flex;
            gap: 30px;
            @media (max-width: 575px) {
              gap: 12px;
            }
            p,
            h4 {
              text-align: left;
              color: var(--white);
              @media (max-width: 1366px) {
                max-width: 300px;
              }
              @media (max-width: 1199px) {
                max-width: 250px;
              }
              @media (max-width: 991px) {
                max-width: 100%;
              }
            }
            &:first-child,
            :nth-child(2) {
              padding-bottom: 50px;
              @media (max-width: 1366px) {
                padding-bottom: 25px;
              }
              @media (max-width: 1199px) {
                padding-bottom: 25px;
              }
              @media (max-width: 991px) {
                padding-bottom: unset;
              }
            }
          }
        }
      }
      .triskelWalletLast {
        @media (max-width: 991px) {
          text-align: left;
          display: flex;
          padding-top: 15px;
          gap: 30px;
        }
        @media (max-width: 575px) {
          gap: 12px;
        }
        p {
          @media (max-width: 991px) {
            margin: unset;
            max-width: 100%;
          }
        }
      }
    }
  }
}
