.franchiseeBanner{
background-image: url("../../assets/franchiseBanner.png");
background-repeat: no-repeat;
background-size: cover;
height:692px;
background-position: center;
@media(max-width:991px){
    height: 500px;
}
h1{
    color: var(--white);
    font-size: var(--heading80);
    font-weight: 500;
    font-family: Poppins;
    text-align: center;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
}

.stepBlock{
    margin: 90px 0;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 103px;

    @media(max-width:991px){
        flex-direction: column;
        justify-content: center;
        gap: 40px;
    }

    h2{
        background: var(--headinggradient);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-size: var( --heading40);
        line-height: 80px;
        font-weight: 600;
        // margin-bottom: 40px;
        @media(max-width:991px){
            line-height: 50px;
        }
        @media(max-width:767px){
            line-height: 40px;
        }
    }
    .stepPoints{
        ul{
            position: relative;
            margin-bottom: 60px;
    
            li{
                list-style-image: url("../../assets/stepIcon.svg");
                list-style-position: outside;
                margin-bottom: 24px;  
    
                &::marker{
                    width: 24px;
                    height: 24px;
                    line-height: 24px;
                }
                span{
                    color: var(--white);
                    font-size: var(--largeParaGraph);
                    font-weight: 500;
                    font-family: Poppins;
                    max-width: 700px;
                    margin-left: 18px;
                    padding: 2px;
                    display: inline-block;
                }
               
            
            }
            &::after{
                content: "";
                position: absolute;
                border-right: 1px dashed #898989;
                height: 95%;
                width: 1px;
                position: absolute;
                top: 9px;
                left: 18px;
                z-index: -1;
            } 
        }
    }
    .cardBlock{
        .partnerCard{
            background-color: var(--innercardbg);
            padding: 40px  50px 100px 50px;
            max-width: 692px;
            width: 100%;
            p{
                padding: 16px 0 50px 0;
            }
            @media(max-width:991px){
                padding: 30px;
            }
            button{
                width: 100%;
                max-width: 318px;
                @media(max-width:991px){
                    max-width: 240px;
                }
            }
        }
    }
    .franchiseeCard{
        flex: 1;
    }
   
}