.chooseMerchant {
  background-image: url("../../assets/choosemrbg.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 100%;
  height: 100%;
  width: 100%;
  padding: 100px 0 147px 0;
  @media (max-width: 991px) {
    padding: 50px 0;
  }
  @media (max-width: 575px) {
    padding: 30px 0;
  }
  .chooseTriskel {
    &_featurepage {
      color: var(--innercardbg);
      font-size: var(--headingFour);
      font-weight: 400;
      text-align: center;
      padding-bottom: 16px;
      @media (max-width: 991px) {
        padding-bottom: 7px;
      }
    }
    &_head {
      color: var(--bodybg);
      font-size: var(--heading50);
      font-weight: 600;
      max-width: 779px;
      width: 100%;
      text-align: center;
      margin: auto;
      padding-bottom: 50px;
      @media (max-width: 991px) {
        padding-bottom: 10px;
      }
    }
    .chooseCards {
      &_inner {
        display: flex;
        background-color: transparent;
        border-width: 1px 0px 1px 0px;
        border-color: var(--bordercard);
        border-style: solid;
        padding: 40px 0 16px;
        min-height: 204px;
        gap: 30px;
        @media (max-width: 991px) {
          padding: 36px 0 8px;
          min-height: 184px;
        }
        &_right {
          h4 {
            font-weight: 500;
            font-size: var(--headingFour);
            color: var(--bodybg);
            padding-bottom: 10px;
          }
          ul {
            list-style-image: url("../../assets/bullet.svg");
            padding-left: 15px;
            li {
              max-width: 452px;
              width: 100%;
              color: var(--bodybg);
              font-size: var(--largeParaGraph16);
              font-weight: 300;
              line-height: 30px;
              padding-bottom: 8px;
              @media (max-width: 575px) {
                line-height: 28px;
              }
              &:last-child {
                padding-bottom: 0;
              }
            }
          }
        }
      }
      .ant-row {
        .ant-col {
          @media (max-width: 767px) {
            width: 100%;
            &:nth-child(3),
            &:nth-child(4) {
              div {
                border-bottom: 0;
              }
            }
            &:nth-child(1) {
              div {
                border-top: 0;
              }
            }
          }
          &:first-child,
          &:nth-child(2) {
            div {
              border-bottom: 0;
            }
          }
        }
      }
    }
  }
}
