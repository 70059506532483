.token {
  padding-top: 161px;
  @media (max-width:767px) {
    padding-top:40px;
    
  }
  &_data {
    display: flex;
    @media (max-width: 1366px) {
      // flex-flow: wrap;
    }
    @media (max-width:767px) {
     position: relative;
    }
    &_left {
      img {
        max-width: 583px;
      }
      h2 {
        font-size: var(--heading60);
       background:var(--headinggradient);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        max-width: 661px;
        font-weight: 600;
        line-height: 60px;
        padding: 24px 0 70px 0;
        @media (max-width:767px) {
            max-width: 352px;
            line-height: 50px;
        }
        @media (max-width:575px) {
            line-height: 44px;
        }
      }
      .utilityData {
        display: flex;
        gap: 40px;
        @media (max-width:415px) {
          margin-top: 20px;
          
        }
        @media (max-width:1199px) {
            flex-wrap: wrap;
            max-width: 400px; 
        }
        &_left {
          display: flex;
          align-items: flex-start;
          gap: 30px;
          div {
            h4 {
              font-size: var(--headingFour);
              color: var(--white);
              padding-bottom: 16px;
              @media (max-width:575px) {
                padding-bottom: 10px;
                
              }
            }
          }
        }
      }
    }
    &_right {
      img {
        max-width: 583px;
        @media (max-width:1199px) {
            width: 100%;
        }
        @media (max-width:767px) {
            position: absolute;
            top:90px;
            right: 0;
            width: 211px;
           }
           @media (max-width:415px) {
            top:111px;
            
           }
      }
    }
  }
}
