.deepdive {
  background: linear-gradient(
    180deg,
    #d1d6d9 0%,
    #ced3d6 25%,
    #ccd0d3 50%,
    #c2c6cc 100%
  );
  position: relative;
  padding: 100px 0;
  box-sizing: border-box;
  height: auto;

  @media (max-width: 1199px) {
    background-color: #cfd3d7;
  }
  @media (max-width: 991px) {
    padding: 40px 0;
  }

  &_video {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
    transform: translate(-50%, -50%);
    z-index: 1;
    object-fit: initial;

    @media (max-width: 1199px) {
      display: none;
    }
  }

  h2 {
    color: var(--bodybg);
    font-size: var(--heading60);
    line-height: 60px;
    font-weight: 600;
    max-width: 781px;
    padding: 0px 15px 50px 15px;
    line-height: 70px;
    @media (max-width: 991px) {
      padding: 0px 15px 16px;
    }
    @media (max-width: 575px) {
      line-height: 34px;
    }
  }

  .deepdiveinner {
    position: relative;
    z-index: 2;
    background: transparent;
    margin: 0 auto;

    @media (max-width: 1199px) {
      transform: none;
      position: static;
      padding: 30px 15px;
    }
    @media (max-width: 767px) {
      padding: 10px 15px;
    }

    &_feature {
      display: flex;
      gap: 60px;

      @media (max-width: 1700px) {
        gap: 22px;
      }
      @media (max-width: 991px) {
        flex-flow: wrap;
        gap: 0;
      }

      &_listing {
        .listingItems {
          display: flex;
          gap: 27px;
          align-items: first baseline;

          @media (max-width: 575px) {
            gap: 13px;
          }

          span {
            color: rgba(18, 18, 18, 0.4);
            font-size: var(--headingFour);
            font-weight: 600;
          }

          div {
            max-width: 533px;
            width: 100%;
            border-top: 1px solid var(--borderColor);
            border-bottom: 1px solid var(--borderColor);
            padding: 16px 0 17px 0;

            @media (max-width: 991px) {
              max-width: 100%;
            }

            h4 {
              color: var(--bodybg);
              font-weight: 500;
              font-size: var(--headingFour);
            }

            p {
              font-weight: 300;
              color: var(--bodybg);
              font-size: var(--largeParaGraph16);
            }
          }
        }
      }
    }
  }
}
