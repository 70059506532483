.pricingPage {
  padding: 100px 15px;
  @media (max-width: 991px) {
    padding: 50px 0;
  }
  @media (max-width: 575px) {
    padding: 30px 0;
  }
  h5 {
    text-align: center;
  }
  h2 {
    max-width: 676px;
    margin: auto;
    padding-bottom: 100px;
    @media (max-width: 767px) {
      padding-bottom: 75px;
    }
  }
  .cryptoPayment {
    display: flex;
    max-width: 1276px;
    width: 100%;
    margin: auto;
    gap: 22px;
    @media (max-width: 991px) {
      justify-content: center;
      align-items: center;
      gap: 20px;
    }
    @media (max-width: 767px) {
      flex-direction: column;
      gap: 90px;
    }
    &_card {
      background-color: var(--bg-181818);
      max-width: 671px;
      width: 100%;
      min-height: 371px;
      @media (max-width: 767px) {
        min-height: 236px;
        max-width: 100%;
      }
      &:nth-child(1) {
        padding-left: 57px;
        img {
          margin-top: -76px;
          @media (max-width: 767px) {
            width: 150px;
            margin-top: -49px;
          }
        }
      }
      &:nth-child(2) {
        padding-left: 49px;
        img {
          margin-top: -125px;
          @media (max-width: 767px) {
            width: 190px;
            margin-top: -84px;
          }
        }
      }
      h4 {
        font-weight: 500;
        font-size: var(--headingThree);
        padding-bottom: 21px;
        color: var(--white);
      }
      p {
        padding-left: 30px;
        color: var(--white);
        font-size: var(--largeParaGraph);
        max-width: 320px;
        font-weight: 300;
        line-height: 34px;
      }
    }
  }
  button {
    display: block;
    margin: auto;
    margin-top: 80px;
    @media (max-width: 767px) {
      margin-top: 30px;
    }
  }
}
