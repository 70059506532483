.architecting-main {
  color: #fff;
  h5 {
    color: var(--parrotColor);
    font-size: var(--headingFour);
    font-weight: 400;
    line-height: 30px;
  }
  h2 {
    font-size: var(--heading50);
    background: var(--headinggradientTwo);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    padding-bottom: 20px;
    text-align: center;
    font-weight: 600;
    line-height: 60px !important;
    margin-bottom: 0 !important;
    margin-top: 16px;
    @media (max-width: 767px) {
      line-height: 40px !important;
      margin-top: 10px;
      padding-bottom: 10px;
    }
  }
  .triskelPay {
    background-image: url("../../assets/TriskelPay-bg.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% 100%;
    height: 100%;
    width: 100%;
    padding: 226px 0 213px 0;
    @media (max-width: 991px) {
      padding: 170px 0 50px 0;
    }

    &_content {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 30px;
      //   flex-flow: wrap;
      @media (max-width: 991px) {
        flex-direction: column;
        align-items: flex-start;
      }
    }
    &_left {
      color: var(--white);
      text-align: left;
      h1 {
        font-size: var(--heading70);
        line-height: 86px;
        font-weight: 500;
        @media (max-width: 1440px) {
          line-height: 60px;
        }
        @media (max-width: 768px) {
          line-height: 35px;
        }
      }
      p {
        font-size: var(--headingFour);
        line-height: 34px;
        margin-top: 16px;
        color: var(--white);
        max-width: 644px;
        width: 100%;
        @media (max-width: 991px) {
          margin-top: 10px;
          line-height: 22px;
        }
      }
      &_btn {
        margin-top: 50px;
        @media (max-width: 991px) {
          margin-top: 20px;
        }
      }
    }
    &_right {
      img {
        max-width: 727px;
        width: 100%;
      }
    }
  }
}
.quickBenefits {
  background-color: var(--quickBenefitsBg);
  padding: 100px 0;
  @media (max-width: 991px) {
    padding: 50px 0;
  }
  @media (max-width: 575px) {
    padding: 30px 0;
  }
  &_top {
    h2 {
      text-align: center;
      color: var(--white);
      font-size: var(--heading50);
      font-weight: 500;
      margin-bottom: 50px;
    }
    &_quickCards {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 20px;
      @media (max-width: 1200px) {
        flex-flow: wrap;
        align-items: center;
        justify-content: center;
      }

      .quickCard {
        background-color: var(--innercardbg);
        padding: 30px;
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        justify-content: center;
        gap: 30px;
        max-width: 384px;
        width: 100%;
        height: 360px;
        text-align: left;
        @media (max-width: 1200px) {
          gap: 20px;
          padding: 20px;
        }
        @media (max-width: 991px) {
          height: 270px;
        }
        @media (max-width: 820px) {
          height: 100% !important;
          max-width: 100%;
        }
        h4 {
          font-size: var(--headingFour);
          color: #d1d1d1;
          font-weight: 600;
        }
        p {
          font-size: var(--largeParaGraph16);
          color: var(--white);
          font-weight: 300;
          margin-top: 15px;
        }
      }
    }
  }
}
.revolutionizingPayments {
  text-align: center;
  margin-top: 130px;
  @media (max-width: 991px) {
    margin-top: 50px;
  }
  @media (max-width: 567px) {
    margin-top: 30px;
  }
  &_text {
    max-width: 915px;
    width: 100%;
    text-align: center;
    margin: 0 auto;
  }

  p {
    font-size: var(--largeParaGraph);
    color: var(--white);
  }
  img {
    margin-top: 30px;
    max-width: 686px;
    width: 100%;
  }
}
.paymentsMade {
  background-color: var(--innercardbg);
  padding: 73px 0;
  padding-bottom: 20px !important;
  @media (max-width: 1199px) {
    padding: 30px 0 40px !important;
  }

  &_content {
    display: flex;
    justify-content: space-between;
    gap: 15px;
    @media (max-width: 1199px) {
      flex-direction: column;
    }
  }
  &_left {
    &_cryptoTest {
      max-width: 560px;
      width: 100%;
    }
    h2 {
      text-align: left !important;
    }
    p {
      font-size: var(--largeParaGraph);
      font-weight: 300;
      line-height: 32px;
      color: var(--white);
      margin-top: 20px;
      margin-bottom: 74px;
      @media (max-width: 991px) {
        margin-top: 0;
        margin-bottom: 20px;
        line-height: 30px;
        padding-top: 0;
      }
    }
    a {
      color: var(--parrotColor);
      font-size: var(--largeParaGraph);
      font-weight: 400;

      span {
        margin-left: 20px;
        @media (max-width: 991px) {
          margin-left: 5px;
        }
      }
    }
  }
  &_right {
    position: relative;
    @media (max-width: 1199px) {
      margin-top: 30px;
    }
    .cardsLine {
      position: absolute;
      z-index: 2;
      top: -10px;
      left: -6px;
      @media (max-width: 1199px) {
        display: none;
      }
      img {
        max-width: 863px;
        width: 100%;
      }
    }
    .cardsContainer {
      display: flex;
      height: 700px;
      @media (max-width: 1199px) {
        height: auto;
        gap: 20px;
        align-items: center;
        justify-content: center;
      }
      @media (max-width: 991px) {
        flex-flow: wrap;
      }
    }
    .paymentsMadeCard {
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      max-width: 284px;
      width: 100%;
      padding: 20px;
      position: relative;
      @media (max-width: 1199px) {
        padding: 20px !important;
        margin: 0 !important;
        min-height: 343px;
        min-width: 32%;
      }
      @media (max-width: 767px) {
        min-width: 100%;
      }

      &:first-child {
        background-image: url("../../assets/FirstBg.svg");
        padding-top: 73px;
        margin-top: 19%;
        @media (max-width: 1199px) {
          background-image: url("../../assets/thBg.svg");
        }
      }
      &:nth-child(2) {
        background-image: url("../../assets/SecBg.svg");
        padding-top: 95px;
        margin-top: 9.3%;
      }
      &:nth-child(3) {
        background-image: url("../../assets/thBg.svg");
        padding-top: 104px;
      }
      img {
        margin-bottom: 16px;
      }
      h3 {
        font-size: var(--headingFour);
        font-weight: 600;
        line-height: 34px;
        white-space: nowrap;
      }
      p {
        color: var(--white);
        font-size: var(--largeParaGraph16);
        line-height: 30px;
        font-weight: 300;
        margin-top: 16px;
        @media (max-width: 991px) {
          margin-top: 4px;
        }
      }
      h4 {
        color: #262626;
        font-size: var(--heading80);
        font-weight: 700;
        // margin-top: 118px;
        text-align: right;
        line-height: 77%;
        position: absolute;
        bottom: 20px;
        right: 20px;
        @media (max-width: 1199px) {
          font-size: 40px;
          position: unset;
          color: #373737;
          position: absolute;
          bottom: 20px;
        }
        @media (max-width: 575px) {
          position: static;
          line-height: unset;
        }
      }
    }
  }
}
.dashboardinsight {
  display: flex;
  gap: 30px;
  padding-bottom: 80px;
  padding-top: 30px;
  @media (max-width: 991px) {
    flex-direction: column;
    padding-top: 15px;
    padding-bottom: 30px;
  }
  h2,
  p {
    text-align: left;
  }
  &_left {
    max-width: 693px;
    width: 100%;
    &_text {
      color: var(--textd1d1);
      font-size: var(--largeParaGraph);
      font-weight: 300;
      max-width: 516px;
    }
    &_realtext {
      padding-left: 26px;
      line-height: 36px;
      padding-top: 46px;
      color: var(--white);
      font-size: var(--largeParaGraph);
      font-weight: 300;
    }
  }
  &_right {
    width: 100%;
    margin-top: 122px;
    display: flex;
    justify-content: flex-end;
    @media (max-width: 991px) {
      justify-content: flex-start;
      margin-top: unset;
    }
    img {
      width: 100%;
      max-width: 832px;
    }
  }
}
.commonSecbg {
  background-color: var(--quickBenefitsBg);
  .frequentlyAsked {
    padding: 80px 0;
    margin-top: 0;
    padding: 15px;
    h5 {
      text-align: center;
    }
  }
}
