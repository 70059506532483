.getInTouch {
  &_inner {
    padding: 50px 0;
    &_triskelPay {
      display: flex;
      gap: 100px;
      align-items: center;
      background-color: var(--triskelCards);
      max-width: 1273px;
      border-radius: 10px;
      width: 100%;
      margin: auto;
      padding: 61px 41px;
      @media (max-width: 1366px) {
        gap: 30px;
      }
      @media (max-width: 991px) {
        flex-direction: column;
        gap: 20px;
        padding: 20px 20px 40px 20px;
      }
      img {
        width: 100%;
        max-width: 442px;
        @media (max-width: 767px) {
          max-width: 250px;
        }
      }
    }
    h2 {
      margin-top: 0;
      padding-bottom: 70px;
      @media (max-width: 767px) {
        padding-bottom: 30px;
      }
    }
    &_right {
      width: 100%;
      @media (max-width: 991px) {
        max-width: 510px;
        width: 100%;
        margin: auto;
      }
      .getInTouchInputs {
        width: 100%;
        &_inner {
          display: flex;
          gap: 79px;
          @media (max-width: 575px) {
            flex-direction: column;
            gap: 0px;
          }
        }
        .triskelpayInput {
          max-width: 243px;
          width: 100%;
          @media (max-width: 575px) {
            max-width: 100%;
          }
          label {
            font-weight: 400;
            color: var(--white);
            font-size: var(--largeParaGraph16);
            margin-bottom: 15px;
            @media (max-width: 767px) {
              margin-bottom: 4px;
            }
          }
          input {
            background-color: transparent;
            border-bottom: 1px solid var(--inputborder);
            padding: 10px 3px;
            height: 50px;
            margin-bottom: 30px;
          }
        }
      }
    }
    button {
      height: 49px !important;
      margin-top: 8px;
    }
  }
}
