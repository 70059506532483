.registerMerchant {
  background-color: var(--white);
  // height: 100vh;
  font-family: "Roboto", sans-serif !important;
  &_main {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 237px;
    padding: 89px 0;
    @media (max-width: 1610px) {
      justify-content: space-between;
      gap: 20px;
      padding: 30px 15px;
    }
    @media (max-width: 991px) {
      flex-direction: column;
    }
  }
  &_left {
    text-align: center;
    &_logo {
      margin-bottom: 20px;
      img {
        max-width: 240px;
        width: 100%;
        @media (max-width: 480px) {
          max-width: 200px;
        }
      }
    }
    &_content {
      img {
        max-width: 483px;
        width: 100%;
      }
    }
    h2 {
      font-size: var(--heading50);
      font-style: normal;
      font-weight: 700;
      line-height: 57px;
      color: var(--black1a202e);
      margin-top: 45px;
    }
    .textPayment {
      max-width: 477px;
      width: 100%;
      margin: 28px auto;
    }
    p {
      font-weight: 400;
      line-height: 24px;
      strong {
        color: #1a202e !important;
      }
    }
  }
  &_right {
    border-radius: 20px;
    background: var(--lightf1f5f9);
    padding: 36px 50px;
    max-width: 720px;
    width: 100%;
    @media (max-width: 1200px) {
      padding: 25px;
    }
    &_form {
      display: flex;
      flex-direction: column;
      gap: 22px;
    }
    h3 {
      color: var(--black1a202e);
      margin-bottom: 23px;
      font-weight: 700;
      font-size: var(--heading28);
      line-height: 20px;
      white-space: nowrap;
    }
    .customInput {
      label {
        color: var(--black1a202e);
        sup {
          display: none;
        }
      }
      input {
        background: var(--white);
        width: 100%;
        color: var(--black1a202e);
      }
    }
    .greenbutton {
      max-width: 100% !important;
      border-radius: 10px !important;
      margin-top: 12px;
    }
    .uploderMerchant {
      display: flex;
      align-items: center;
      gap: 10px;
      justify-content: space-between;
      margin-top: 24px;
      > div {
        flex: 1;
        width: 100%;
      }
      @media (max-width: 567px) {
        flex-direction: column;
        gap: 35px;
        .uploader {
          width: 100%;
        }
      }
    }
  }
  &_footer {
    background-color: var(--lightf1f5f9);
    padding: 17px;
    p {
      text-align: center;
      color: #353535;
      font-size: var(--textfourteen);
    }
  }
}
.phoneNumber {
  label {
    color: var(--black1a202e);
    font-weight: 300;
    margin-bottom: 10px;
    font-size: var(--textfourteen);
    display: flex;
  }
  .react-tel-input {
    display: flex;
    align-items: center;
    gap: 12px;
    flex-direction: row-reverse;
    width: 100%;
    justify-content: flex-end;
  }
  .form-control {
    height: 59px !important;
    border: none !important;
    margin-left: 65px !important;
    padding: 10px 25px !important;
    // max-width: 548px !important;
    width: 100% !important;
    @media (max-width: 991px) {
      height: 45px !important;
    }
  }
  .flag-dropdown {
    border: none !important;
    background-color: var(--white) !important;
    padding: 10px !important;
    border-radius: 10px !important;
  }
  .open {
    z-index: 3 !important;
  }
  .country-list {
    width: 350px !important;
    z-index: 3 !important;
  }
}
.error-message {
  color: red;
  font-size: 12px; // Adjust the size if needed
  margin-top: 4px; // Add space between the input and error message
}
