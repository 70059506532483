.uploader {
  position: relative;
  .idText {
    position: absolute;
    top: 8px;
    left: 10px;
    font-size: var(--textfourteen);
    font-weight: 400;
  }
  label {
    color: var(--black1a202e);
    font-weight: 300;
    font-size: var(--textfourteen);
    display: flex;
    position: absolute;
    top: -27px;
  }
  .ant-upload {
    img {
      max-width: 100px;
      height: 100px;
    }
  }
  .ant-upload-select {
    border-radius: 10px !important;
    border: 1px dashed #c5d0e0 !important;
    background: var(--white) !important;
    // max-width: 195px !important;
    width: 100% !important;
    height: 122px !important;
    padding: 0 38px;
    @media (max-width: 1230px) {
      padding: 0 20px;
    }
    @media (max-width: 1133px) {
      padding: 0;
    }
    button {
      cursor: pointer !important;
    }
  }
  .UplodeIcon {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-top: 13px;
    span {
      color: var(--black1a202e);
      font-size: var(--textfourteen);
      font-weight: 500;
      white-space: nowrap;
    }
  }
  .ant-upload-list-item-container {
    position: absolute;
    z-index: 9;
    top: 11px;
    left: 30px;
    width: 135px !important;

    @media (max-width: 1230px) {
      left: 4px;
      width: 112px !important;
    }
    @media (max-width: 567px) {
      left: 29%;
      width: 135px !important;
    }
    .ant-upload-list-item {
      border: none !important;
      background: #fff !important;
    }
  }
}
