.certificates {
  padding: 100px 15px 0px 15px;

  @media (max-width: 575px) {
    padding: 40px 15px 0 14px;
  }

  h2 {
    font-size: var(--heading60);
    background: var(--headinggradientTwo);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    padding-bottom: 24px;
    text-align: center;
    font-weight: 600;
  }

  &_card {
    display: flex;
    flex-wrap: wrap;
    gap: 15.6px;

    // height: 240px;

    @media (max-width: 1366px) {
      flex-flow: wrap;
    }

    &_inner {
      background: var(--cardbg);
      border: 1px solid #494a4b;
      // border-image-source: var(--cardborder);
      border-radius: 10px;
      padding: 24px 20px;
      position: relative;
      flex: 0 1 calc(24% - 10px);
      height: 100%;
      min-height: 170px;

      &:hover {
        box-shadow: -3px 1px 18px -3px #989b8d;
      }

      @media (max-width: 1366px) {
        max-width: 24%;
        width: 100%;
        flex: unset;
        min-height: 140px;
      }

      @media (max-width: 1199px) {
        max-width: 32%;
      }

      @media (max-width: 991px) {
        max-width: 48%;
      }

      @media (max-width: 575px) {
        max-width: 100%;
      }

      p {
        color: var(--white);
        font-size: var(--headingFour);
        padding-bottom: 12px;
      }

      span {
        color: var(--white);
        opacity: 0.6;
        font-size: var(--largeParaGraph16);
      }

      .greenright {
        position: absolute;
        right: 20px;
        bottom: 20px;
        font-size: var(--textfourteen);
        color: var(--dropborder);
        cursor: pointer;
      }
    }
  }
}