.policyLayout {
  margin-top: 120px;
  $sidebar-width: 340px;
  $sidebar-bg-color: #1d1d1d;
  $active-link-bg-color: #04aa6d;
  $hover-link-bg-color: #555;
  $hover-link-color: white;
  $default-link-color: black;
  $padding: 16px;
  $border-bottom-gradient: linear-gradient(90deg, #eeeeee 0%, #3a3a3a 100%);
  &_main {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    @media (max-width: 767px) {
      flex-direction: column;
      justify-content: center;
      gap: 20px;
    }
  }
  // Sidebar Styles
  .sidebar {
    margin: 0;
    padding: 0;
    width: $sidebar-width;
    background-color: $sidebar-bg-color;
    position: fixed;
    height: 100%;
    overflow-y: scroll;
    max-height: 85vh;
    @media (max-width: 767px) {
      position: unset;
      max-height: 40vh;
      margin: auto;
    }

    a {
      padding: $padding;
      display: block;
      // border-bottom: 1px solid;
      // border-image: linear-gradient(90deg, #eeeeee, rgba(58, 58, 58, 0.3)) 10;
      padding-bottom: 0;
      hr {
        max-width: 211px;
        width: 100%;
        margin: 0;
        margin-top: 16px;
        border: none; 
        height: 1px; 
        background:linear-gradient(333.73deg, #3939397d 52.4%, #eeeeeeb0 173.42%)  !important;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
      span {
        font-size: var(--largeParaGraph16);
        display: block;
        background: var(--headinggradient);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        line-height: 28px;
        text-decoration: none;
      }

      &.active {
        span {
          background-color: $active-link-bg-color;
          color: white;
        }
      }

      &:hover:not(.active) {
        span {
          background-color: $hover-link-bg-color;
          color: $hover-link-color;
        }
      }
    }
  }
  .privacyPolicy,
  .riskDisclaimer,
  .termandUse {
    counter-reset: my-sec-counter;
  }
  // Content Styles
  div.contentSide {
    margin-left: $sidebar-width;
    padding: 1px 50px;
    height: 1000px;
    flex: 1;
    @media (max-width: 767px) {
      margin-left: unset;
    }
    section {
      margin-bottom: 50px;
    }

    h6 {
      font-family: Poppins;
      color: #ffffff;
      font-size: var(--largeParaGraph16);
      font-weight: 600;
      line-height: 32px;
      margin-bottom: 20px;
    }

    h2 {
      font-size: var(--heading60); /* Ensure this variable is defined */
      font-weight: 600;
      // font-size: 72px;
      background: var(--headinggradient);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      line-height: 80px;
      padding-top: 20px;
      margin-bottom: 36px;
      /* Ensure you include a space after media queries */
      @media (max-width: 991px) {
        line-height: 70px;
      }
      @media (max-width: 575px) {
        // line-height: 70px;
        padding-top: 10px;
      }
    }

    h4 {
      color: var(--parrotColor);
      font-size: var(--headingFour);
      font-weight: 600;
      line-height: 32px;
      margin-bottom: 16px;

      &::before {
        counter-increment: my-sec-counter;
        content: counter(my-sec-counter) ". ";
      }
    }
    p {
      font-family: Poppins;

      font-size: var(--largeParaGraph16);
      line-height: 80px;
      font-weight: 300;
      line-height: 32px;
      margin-bottom: 20px;
      &.parrotColour {
        color: var(--parrotColor);
      }
    }
  }

  // Media Queries
  @media (max-width: 700px) {
    .contentSide {
      width: 100%;
      height: auto;
      position: relative;

      a {
        float: left;
      }
    }

    div.contentSide {
      margin-left: 0;
    }
  }

  @media (max-width: 400px) {
    .sidebar a {
      text-align: center;
      float: none;
    }
  }
}

.privacyPolicy {
  h2 {
    font-size: var(--heading70);
    font-weight: 600;
    background: var(--headinggradientTwo);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    line-height: 80px;
    padding-top: 20px;
    margin-bottom: 36px;
    /* Ensure you include a space after media queries */
    @media (max-width: 991px) {
      line-height: 50px;
    }
    @media (max-width: 575px) {
      line-height: 44px;
      padding-top: 10px;
    }
  }
}
