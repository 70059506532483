.downsuperapp {
  background: url("../../../../assets/downloadbg.png"), var(--downloadbg);
  background-size: 100% 100% cover;
  display: flex;
  border-radius: 50px;
  padding-bottom: 140px;
  margin-top: 150px;
  background-repeat: no-repeat;
  background-size: cover;
  margin-bottom: 147px;
  @media (max-width: 1610px) {
    margin: 150px 15px 147px 15px;
  }
  @media (max-width: 1199px) {
    padding-bottom: 33px;
  }
  @media (max-width: 991px) {
    background-size: contain;
    border-radius: 20px;
    margin-top: unset;
  }
  @media (max-width: 767px) {
    margin-bottom: 20px;
   }
  @media (max-width: 575px) {
    position: relative;
  }

  h2 {
    font-size: var(--heading50);
    padding: 80px 50px 50px 50px;
    line-height: 60px;
    font-weight: 600;
    max-width: 670px;
    width: 100%;
    @media (max-width: 1199px) {
      padding: 20px;
    }
    @media (max-width: 767px) {
      line-height: 40px;
      padding: 20px 0;
    }
    @media (max-width: 575px) {
      line-height: 30px;
      max-width: 170px;
    }
  }
  &_left {
    max-width: 670px;
    width: 100%;
    &_buttons {
      display: flex;
      gap: 18px;
      padding-left: 70px;
      @media (max-width: 1199px) {
        padding-left: 20px;
        gap: 12px;
      }
      @media (max-width: 767px) {
        padding-left: 0px;
        gap: 12px;
      }
      a {
        img {
          max-width: 183px;
          width: 100%;
          @media (max-width: 767px) {
            width: 130px;
          }
        }
      }
    }
  }
  &_right {
    img {
      width: 100%;
      padding-top: 37px;
      padding-left: 45px;
      @media (max-width:1366px) {
        padding-left: 0;
      }
      @media (max-width: 575px) {
        position: absolute;
        right: 0;
        width: 260px;
        padding-top: 10px;
      }
      @media (max-width: 480px) {
        width: 210px;
      }
      @media (max-width:380px) {
        width: 144px;
      }
    }
  }
}
