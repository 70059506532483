.applicantHeader {
  background-color: #434343;
  padding: 20px;
  position: relative;
  @media (max-width: 767px) {
    padding: 10px 15px;
  }


  &_content {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 127px;
    @media (max-width: 1200px) {
      gap: 20px;
      justify-content: space-between;
    }
    @media (max-width: 767px) {
      gap: 5px;
    }
  }
  p {
    color: var(--white);
    font-size: var(--headingFour);
    // line-height: 30px;
    font-weight: 500;
    @media (max-width: 1200px) {
      font-size: 12px;
    }
  }
  a {
    display: flex;
    align-items: center;
    gap: 20px;
    color: var(--parrotColor);
    font-size: var(--headingFour);
    font-weight: 600;
    span {
      height: 23px;

      svg {
        height: 23px;
        width: 23px;
      }
    }
    @media (max-width: 1200px) {
      font-size: 12px;
    }
    @media (max-width: 767px) {
      gap: 5px;
      svg {
        width: 15px;
      }
    }
  }
  button {
    background: transparent;
    border: none;
    padding: 0;
    cursor: pointer;
    position: absolute;
    right: 181px;
    @media (max-width: 1569px) {
      right: 20px;
    }
    @media (max-width: 1200px) {
      position: unset;
    }

    @media (max-width: 767px) {
      gap: 5px;
      svg {
        width: 20px;
      }
    }
  }
}
