.keyFeature {
  padding: 107px 0 80px 0;
  background-color: var(--innercardbg);
  @media (max-width: 575px) {
    padding: 20px 0 16px 0;
  }
  &_top {
    display: flex;
    align-items: flex-end;
    @media (max-width: 767px) {
      flex-flow: wrap;
      gap: 16px;
    }
    &_left {
      max-width: 578px;
      width: 100%;
      h2 {
        font-size: var(--heading70);
        font-weight: 600;
        background: var(--headinggradientTwo);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        line-height: 80px;
        padding-top: 20px;
        @media (max-width: 991px) {
          line-height: 50px;
        }
        @media (max-width: 575px) {
          line-height: 44px;
          padding-top: 10px;
        }
      }
    }
    &_right {
      width: 100%;
      display: flex;
      justify-content: center;
      @media (max-width: 767px) {
        justify-content: flex-start;
      }
      p {
        color: var(--white);
        font-weight: 300;
        font-size: var(--largeParaGraph16);
        color: var(--textd1d1);
        max-width: 570px;
        width: 100%;
        @media (max-width: 575px) {
          margin-bottom: 10px;
        }
      }
    }
  }

  &_cards {
    padding-top: 124px;
    display: flex;
    flex-direction: column;
    gap: 48px;
    @media (max-width: 575px) {
      padding-top: 16px;
      gap: 20px;
    }
    &_inner {
      display: flex;
      flex-wrap: wrap;
      gap: 16px;
      @media (max-width: 1366px) {
        flex-flow: wrap;
        justify-content: center;
      }
      .innerItems {
        width: 100%;
        flex: 1;
        background-color: var(--bodybg);
        padding: 30px 24px 30px 30px;
        transition: 0.3s linear;
        border: 2px solid var(--bodybg);
        &:hover {
          transform: scale3d(1.05, 1.05, 1.05);
          background: transparent;
        }
        @media (max-width: 1366px) {
          flex: unset;
          max-width: 300px;
          width: 100%;
        }

        @media (max-width: 767px) {
          max-width: 100%;
          text-align: center;
        }
        span {
          height: 170px;
          display: block;
          @media (max-width: 991px) {
            height: 140px;
          }
          img {
            @media (max-width: 575px) {
              // width: 92px;
            }
          }
        }
        p {
          color: var(--white);
          font-weight: 400;
          font-size: var(--largeParaGraph);
          @media (max-width: 575px) {
            margin-top: 10px;
          }
        }
      }
    }
  }
}
