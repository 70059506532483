.frequentlyAsked {
  margin-top: 148px;
  @media (max-width: 991px) {
    margin-top: 30px;
  }
  h2 {
    text-align: center;
    background: var(--headinggradient);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: var(--heading60);
    line-height: 60px;
    margin-bottom: 18px;
  }
  .ant-collapse {
    max-width: 1280px;
    width: 100%;
    margin: 0 auto;
    .ant-collapse-expand-icon {
      padding: 0 !important ;
      span {
        display: none !important;
      }
      &::after {
        position: absolute;
        right: 0;
        top: 30%;
        transform: translateY(-50%);
        content: "+";
        transform: translate(-90%, -20%);
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        z-index: 1;
        font-weight: 300;
        transform: translateY(-50%);
        font-size: 30px;
        line-height: 19px;
        border-radius: 20px;
        padding: 1px 0px 0px 8px;
        color: var(--white);
        @media (max-width: 575px) {
          font-size: 25px;
          right: -18px;
        }
      }
    }
    .ant-collapse-item {
      padding: 50px;
      border-bottom: 1px solid var(--faqBorder);
      &:last-child {
        border: none;
      }
      @media (max-width: 991px) {
        padding: 25px;
      }
    }
    .ant-collapse-header {
      padding: 0;
    }
    .ant-collapse-header-text {
      font-size: var(--headingFour);
      color: var(--white);
      font-weight: 400;
    }
    .ant-collapse-item-active {
      background-color: var(--innercardbg);
      .ant-collapse-expand-icon {
        &::after {
          position: absolute;
          content: "_";
          padding: 3px 0px 0px 5px;
          z-index: 1;
          color: var(--white);
        }
        span {
          display: none;
        }
      }
    }
    .ant-collapse-content-box {
      padding: 0;
      padding-top: 18px !important;
      p {
        font-size: var(--largeParaGraph16);
        color: var(--textd1d1d1);
        font-weight: 300;
      }
    }
  }
}
