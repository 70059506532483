.explore {
  h2 {
    background: var(--headinggradient);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
    font-size: var(--heading70);
    padding: 0 15px;
    font-weight: 600;
    margin-top: -21px;
    padding-bottom: 14px;
    span {
      background: var(--headinggradient);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
  &_text {
    text-align: center;
    max-width: 899px;
    width: 100%;
    margin: auto;
    padding: 5px 15px 0;
    font-size: var(--largeParaGraph);
    color: var(--textd1d1);
  }
  &_inner {
    &_data {
      display: flex;
      justify-content: space-between;
      gap: 20px;
      margin-top: 38px;
      @media (max-width: 991px) {
        flex-wrap: wrap;
      }
      .innerItems {
        width: 100%;
        flex: 1;
        background-color: var(--bodybg);
        padding: 30px;
        background-color: var(--innercardbg);
        padding: 67px 0 119px 21px;
        @media (max-width: 991px) {
          max-width: 48%;
          padding: 20px;
        }
        @media (max-width: 575px) {
          max-width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
        }
        &_lottieData {
          position: relative;
          @media (max-width: 767px) {
            margin-bottom: 28px;
          }
          &::before {
            content: "";
            position: absolute;
            background-image: url("../../../../assets/expjsonbg.png");
            display: block;
            background-repeat: no-repeat;
            width: 190px;
            height: 190px;
            top: 117px;
            left: 18px;
          }
          .lottiePlayer {
            svg {
              width: 230px !important;
              height: 230px !important;
            }
          }
        }
      }
      h4 {
        font-size: var(--heading26);
        color: var(--white);
        font-weight: 500;
        padding: 60px 0 20px 0;
        padding: 15px 25px 15px 25px;
        @media (max-width: 575px) {
          text-align: center;
        }
      }
      p {
        color: var(--textd1d1);
        font-weight: 300;
        font-size: var(--largeParaGraph);
        max-width: 439px;
        line-height: 34px;
        padding: 0 25px 0 25px;
        @media (max-width: 575px) {
          text-align: center;
          line-height: 28px;
        }
      }
    }
  }
}
